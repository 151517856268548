import { graphql } from 'gatsby';
import { PageWrapper } from 'components/Layout/PageWrapper';
import { MainContainer } from 'components/UI/Containers';
import { Hero } from './components/Hero';
import { Features } from './components/Features';
import { Description } from './components/Description';
import { RelatedServices } from '../ServicePage/components/RelatedServices';

const DoctorPageTemplate = ({ data, pageContext }) => {
  const {
    datoCmsSymmetryDoctor: pageData,
    datoCmsSTranslation: translations
  } = data;

  const {
    doctorExperience,
    doctorCategory,
    doctorEducation,
    doctorAbout,
    doctorCertificatesAndAwards,
    doctorSeeAll,
    doctorAreasOfActivity,
    doctorServices,
  } = translations;

  const {
    seo,
    fullName,
    role,
    slug,
    slogan,
    photo,
    experienceYears,
    category,
    education,
    about,
    certificates,
    areasOfActivity,
    doctorServicesList,
  } = pageData;

  const { seoTitle, seoDescription, seoImage } = seo || {};

  return (
    <PageWrapper
      pageData={pageContext}
      {...{ seoTitle, seoDescription, seoImage }}
    >
      <MainContainer>
        <Hero {...{ fullName, role, slogan, photo }} />
        <Features {...{ experienceYears, category, education, doctorExperience, doctorCategory, doctorEducation }} />
        <Description {...{
          doctorAbout,
          about,
          doctorCertificatesAndAwards,
          certificates,
          doctorSeeAll,
          doctorAreasOfActivity,
          areasOfActivity
        }}
        />
      </MainContainer>
      <RelatedServices serviceRelated={doctorServices} relatedServices={doctorServicesList} />
    </PageWrapper>
  );
};

export default DoctorPageTemplate;

export const query = graphql`
  query DoctorPageQuery($locale: String!, $id: String!) {
    datoCmsSTranslation(locale: { eq: $locale }) {
      doctorExperience
      doctorCategory
      doctorEducation
      doctorAbout
      doctorCertificatesAndAwards
      doctorSeeAll
      doctorAreasOfActivity
      doctorServices
    }
    datoCmsSymmetryDoctor(locale: { eq: $locale }, originalId: { eq: $id }) {
      locale
      fullName
      role
      slug
      seo {
        seoTitle: title
        seoDescription: description
        seoImage: image {
          seoImageUrl: url
        }
      }
      slogan
      photo {
        gatsbyImageData(width: 472, height: 641)
        url
        format
      }

      experienceYears
      category
      education

      about {
        value
        links
        blocks
      }

      certificates {
        url
        format
      }

      areasOfActivity

      doctorServicesList: doctorServices  {
        id: originalId
        title
        slug
      }
    }
  }
`;
