import { Qube } from 'components/Qube';
import { Wrapper } from './styles';

const Features = (props) => {
  const {
    experienceYears,
    category,
    education,
    doctorExperience,
    doctorCategory,
    doctorEducation
  } = props;

  return (
    <Wrapper>
      <Qube title={experienceYears} info={doctorExperience} />
      <Qube title={category} info={doctorCategory} />
      <Qube title={education} info={doctorEducation} />
    </Wrapper>
  );
};

export { Features };
