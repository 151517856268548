import Image from 'components/Image';
import { HeroWrapper, DoctorPicture, DoctorInfo, DoctorSlogan, DoctorName, DoctorRole } from './styles';
import { CTA } from '../../../../components/Layout/Header/styles';

const Hero = ({ fullName, role, slogan, photo }) => {
  return (
    <HeroWrapper>
      <DoctorPicture>
        <Image image={photo} alt={fullName} />
      </DoctorPicture>
      <DoctorInfo>
        <DoctorSlogan>{slogan}</DoctorSlogan>
        <DoctorRole>{role.split(',').map((r, index) => <li key={index}>{r}</li>)}</DoctorRole>
        <DoctorName>{fullName}</DoctorName>
        <CTA className="hero-cta" href="#contact"><span>Записатись на консультацію</span></CTA>
      </DoctorInfo>
    </HeroWrapper>
  );
};

export { Hero };
