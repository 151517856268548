import { StyledStructured } from 'components/StyledStructured';
import { Wrapper, DoctorAboutLabel, DoctorAboutInfo, DoctorAboutText, CertificatesAndAwardsTitle, CertificatesAndAwards, CertificatesAndAwardsData } from './styles';
import Image from '../../../../components/Image';
import { Included } from '../../../ServicePage/components/Included';

const Description = (props) => {
  const {
    doctorAbout,
    about,
    doctorCertificatesAndAwards,
    certificates,
    doctorSeeAll,
    doctorAreasOfActivity,
    areasOfActivity
  } = props;
  return (
    <Wrapper>
      <DoctorAboutLabel>{doctorAbout}</DoctorAboutLabel>
      <DoctorAboutInfo>
        <DoctorAboutText>
          <StyledStructured
            data={about}
          />
        </DoctorAboutText>
        {certificates && certificates.length > 0 && (
          <CertificatesAndAwards>
            <CertificatesAndAwardsTitle>{doctorCertificatesAndAwards}</CertificatesAndAwardsTitle>
            <CertificatesAndAwardsData>
              {certificates.map((cert) => <Image image={cert} />)}
            </CertificatesAndAwardsData>
          </CertificatesAndAwards>
        )}

        {areasOfActivity && areasOfActivity.length > 0 && (
          <Included included={areasOfActivity} serviceIncluded={doctorAreasOfActivity} />
        )}
      </DoctorAboutInfo>
    </Wrapper>
  );
};

export { Description };
