import styled from 'styled-components';
import { RoundedContainer } from 'styles/elements';
import { media } from 'styles/mixins';

export const HeroWrapper = styled(RoundedContainer)`
  display: flex;
  flex-direction: row;
  border-radius: 30px;
    gap: 33px;
  padding: 0;

    ${media.mobile`
      flex-direction: column;
      gap: 18px;
    `}
`;

export const DoctorPicture = styled.div`
    border-radius: 30px;
    overflow: hidden;
    width: calc(100% / 3 - 22px);
    
    ${media.tablet`
      width: calc(100% / 2 - 22px);
    `}

    ${media.mobile`
      width: 100%;
    `}
    
    > div {
        width: 100%;
        height: 100%;
    }
`;

export const DoctorInfo = styled.div`
  padding: 54px 54px 40px 0;
    display: flex;
    flex-direction: column;
    width: calc(100% - (100% / 3 + 9px));
    
    .hero-cta {
        width: fit-content;
    }

    ${media.mobile`
      width: 100%;
      padding: 0 18px 18px 18px;
      
      .hero-cta {
        width: 100%;
      }
    `}
`;

export const DoctorSlogan = styled.p`
  font-size: 40px;
  line-height: 54px;
    max-width: 654px;
    margin-left: auto;

    ${media.tablet`
      font-size: 18px;
      line-height: 27px;
      margin-left: initial;
      margin-bottom: 36px;
    `}
    
    &:before {
        color: #A19EA5;
        content: "«";
    }

    &:after {
        color: #A19EA5;
        content: "»";
    }
`;

export const DoctorName = styled.h1`
    font-size: 54px;
    font-weight: 600;
    line-height: 54px;
    margin-bottom: 36px;
    margin-top: 13px;

    ${media.mobile`
      font-size: 27px;
      line-height: 36px;
      margin-bottom: 27px;
      margin-top: 18px;
    `}
`;

export const DoctorRole = styled.ul`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: auto;

    ${media.mobile`
      margin-bottom: 18px;
    `}
    
    li {
        display: inline-flex;
        border: 1px solid #F582C9;
        padding: 9px 15px;
        border-radius: 40px;

        ${media.mobile`
          font-size: 15px;
          line-height: 18px;
        `}
    }
`;
