import styled from 'styled-components';
import { media } from 'styles/mixins';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 33px;
    
    ${media.tablet`
      flex-direction: column;
    `}

    ${media.mobile`
      margin-top: 54px;
      gap: 18px;
    `}
`;

export const DoctorAboutLabel = styled.div`
    font-size: 40px;
    font-weight: 600;
    line-height: 54px;
    width: calc(100% / 3 - 22px);
    
    ${media.mobile`
      width: 100%;
      font-size: 27px;
      font-weight: 500;
      line-height: 36px;
    `}
`;

export const DoctorAboutInfo = styled.div`
    width: calc(100% - (100% / 3 + 9px));

    ${media.mobile`
      width: 100%;
    `}
`;

export const DoctorAboutText = styled.div`
    p {
        color: #6E707C;
        margin-bottom: 18px;
        font-size: 32px;
        font-weight: 600;
        line-height: 40px;
        
        ${media.mobile`
          font-size: 22px;
          font-weight: 600;
          line-height: 27px;
        `}
    }
`;

export const CertificatesAndAwards = styled.div``;

export const CertificatesAndAwardsTitle = styled.div``;

export const CertificatesAndAwardsData = styled.div``;

export const AreasOfActivity = styled.div``;

export const AreasOfActivityTitle = styled.div``;

export const AreasOfActivityData = styled.ul``;
